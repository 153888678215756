<template>
<b-card :title="$t('tasks')">
  <b-row>
    <b-col md="3" class="mb-2">
        <b-button-group>
            <b-button size="sm" variant="outline-secondary" title="Скинути фільтр" @click="clearFilter"><fa icon="filter" /></b-button>
            <b-button size="sm" variant="outline-dark" @click="hasOneRowOpen ? hideAllDetails() : showAllDetails()">{{ hasOneRowOpen ? 'Згорнути' : 'Розгорнути'}}</b-button>
        </b-button-group>
    </b-col>
    <b-col md="9" class="text-right">
      <b-button-group>
        <b-dropdown size="sm" id="dropdown-form" :text="$t('take_to_working')" ref="dropdown" variant="outline-dark">
          <b-dropdown-form>
            <b-form-group :label="$t('process_name')" label-for="dropdown-form-process_name">
              <!--<b-form-input
              id="dropdown-form-process_name"
              size="sm" v-model="textProcess" :placeholder="$t('text_process_name')"></b-form-input>
              <b-dropdown-divider></b-dropdown-divider>-->
              <b-form-select v-model="selectedProcess" :options="processOptions" size="sm" class="mb-1"></b-form-select>

              <b-button variant="dark" size="sm" @click="takeToWork">{{ $t('take_to_working') }}</b-button>
            </b-form-group>
          </b-dropdown-form>
        </b-dropdown>
        <!--<b-button size="sm" variant="outline-warning" @click="takeToWork">{{ $t('take_to_working') }}</b-button>-->

        <b-button size="sm" variant="outline-dark" @click="setStatus(8)">{{ $t('status_ready') }}</b-button>
        <b-button size="sm" variant="outline-dark" @click="setStatus(9)">{{ $t('cancel') }}</b-button>
      </b-button-group>
    </b-col>
  </b-row>
  <b-table
    responsive
    show-empty
    sort-icon-left
    :items="items"
    :fields="fields"
    :current-page="currentPage"
    :per-page="0"
    v-model="currentItems">
    <template slot="top-row" slot-scope="{ fields }">
      <td v-for="field in fields" :key="field.key">
        <b-form-input v-if="field.typesort === 'text'" size="sm" :placeholder="field.label" @change="searchByText(field.key, $event)"></b-form-input>
        <b-input-group v-if="field.typesort === 'doubleinput'">
          <b-form-input v-model="date_start" @change="searchByDate('start_date', $event)" type="date" size="sm"></b-form-input>
          <b-form-input v-model="date_end" @change="searchByDate('end_date', $event)" type="date" size="sm"></b-form-input>
        </b-input-group>
        <!-- delivery -->
        <div v-if="field.key === 'delivery'" class="form-group">
          <select class="form-control form-control-sm" @change="searchBySelect(field.key, $event)">
            <option value="">--</option>
            <option v-for="item in deliveries" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <!-- payment -->
        <div v-if="field.key === 'payment_type'" class="form-group" >
          <select class="form-control form-control-sm" @change="searchBySelect(field.key, $event)">
            <option value="">--</option>
            <option v-for="item in payments" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <!-- payment status id -->
        <div v-if="field.key === 'payment_status_id'" class="form-group">
          <select class="form-control form-control-sm"  @change="searchBySelect(field.key, $event)">
            <option value="">--</option>
            <option v-for="item in paymentStatuses" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <!-- order status id -->
        <div v-if="field.key === 'order_status'" class="form-group">
          <select class="form-control form-control-sm" @change="searchBySelect(field.key, $event)">
            <option value="">--</option>
            <option v-for="item in orderStatuses" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
      </td>
    </template>
    <template v-slot:head(selected)>
      <div class="custom-control custom-checkbox">
        <input
        type="checkbox"
        class="custom-control-input"
        id="check-tasks"
        @click="selectAll">
        <label class="custom-control-label" for="check-tasks"></label>
      </div>
    </template>
    <template v-slot:cell(selected)="row">
      <b-button-group>
        <!--<div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            :id="`checkbox-${row.item.id}`"
            :name="`checkbox-${row.item.id}`"
            :value="row.item.id"
            v-model="selectIds"
            @change="selectAllStages">
            <label class="custom-control-label" :for="`checkbox-${row.item.id}`"></label>
        </div>-->
      <b-button size="sm" @click="row.toggleDetails" class="mr-2" variant="outline-info" pill>
        <fa :icon="row.detailsShowing ? 'minus' : 'plus'" />
      </b-button>
      </b-button-group>
    </template>
    <template v-slot:cell(order_status)="row">
      <b-badge v-if="row.item.order_status_name === 'Новий'" variant="primary">{{ row.item.order_status_name }}</b-badge>
      <b-badge v-else-if="row.item.order_status_name === 'Відміна'" variant="danger">{{ row.item.order_status_name }}</b-badge>
      <b-badge v-else-if="row.item.order_status_name === 'Готово'" variant="success">{{ row.item.order_status_name }}</b-badge>
      <b-badge v-else-if="row.item.order_status_name === 'В роботі'" variant="warning">{{ $t('in_work') }}</b-badge>
      <b-badge v-else>{{ row.item.order_status_name }}</b-badge>
    </template>
    <template v-slot:cell(ready)="row">
      <b-badge variant="light">{{ row.item.ready }}</b-badge>
    </template>
    <!-- subtable -->
    <template v-slot:row-details="row">
      <table class="table table-sm table-light">
        <thead>
          <tr>
            <th></th>
            <th class="text-right">{{ $t('count') }}</th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="(item, idxs) in row.item.products.data" :key="idxs">
              <td v-if="(item.stages || []).length <= 0">
                <div class="custom-control custom-checkbox">
                  <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="`check-tasks-stage-${item.id}`"
                  :value="item.id"
                  v-model="selectIdProduct"
                  @change="selectProduct($event, item.id, row.item.id)">
                    <label class="custom-control-label" :for="`check-tasks-stage-${item.id}`"></label>
                  </div>
              </td>
              <td>
                  <strong>{{ item.group }}</strong><br>
                  <strong>{{ item.product }}</strong>
                  <div>
                    <strong>{{ $t('product_status') }}: </strong>
                    <b-badge v-if="item.status === 'Готово'" variant="success">{{ $t('done') }}</b-badge>
                    <b-badge v-else-if="item.status === 'Новий'" variant="primary">{{ $t('new') }}</b-badge>
                    <b-badge v-else-if="item.status === 'В роботі'" variant="warning">{{ $t('in_work') }}</b-badge>
                    <b-badge v-else-if="item.status === 'Не укомплектовано'" variant="danger">{{ item.status }}</b-badge>
                    <b-badge v-else variant="secondary">{{ item.status }}</b-badge>
                  </div>
                  <table v-if="(item.stages || []).length > 0" class="table table-borderles">
                        <thead>
                            <th></th>
                            <th>№</th>
                            <th>{{ $t('stage') }}</th>
                            <th>{{ $t('characteristic') }}</th>
                            <th>{{ $t('status_ready') }}</th>
                            <th>{{ $t('status') }}</th>
                            <th>{{ $t('photo') }}</th>
                            <th>{{ $t('photo_layout') }}</th>
                        </thead>
                        <tr v-for="(stage, idx) in item.stages" :key="idx">
                            <!--<td v-if="stage.status === 'Новий'">-->
                            <td>
                              <div v-if="stage.status === 'Новий'" class="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  :id="`check-tasks-stage-${stage.id}`"
                                  :value="stage.id"
                                  v-model="selectIdStages"
                                  @change="selectStage($event, stage.id, row.item.id, item.id)">
                                  <label class="custom-control-label" :for="`check-tasks-stage-${stage.id}`"></label>
                              </div>
                            </td>
                            <td class="text-center">{{ idx + 1 }}</td>
                            <td>{{ stage.name }}</td>
                            <td>{{ item.characteristic }}</td>
                            <td>{{ item.quantity }}/{{ stage.ready }}</td>
                            <td>
                              <b-badge v-if="stage.status === 'Готово'" variant="success">{{ $t('done') }}</b-badge>
                              <b-badge v-else-if="stage.status === 'Новий'" variant="primary">{{ $t('new') }}</b-badge>
                              <b-badge v-else-if="stage.status === 'Відміна'" variant="danger">{{ $t('cancel') }}</b-badge>
                              <b-badge v-else variant="warning">{{ stage.status }}</b-badge>
                            </td>
                            <td><b-img-lazy :src="apiUrl + stage.photo" fluid width="150" thumbnail/></td>
                            <td><b-img-lazy :src="apiUrl + stage.photo_layout" fluid width="150" thumbnail/></td>
                        </tr>
                    </table>
                </td>
                <td class="text-right">
                  {{ item.quantity }}
                </td>
            </tr>
        </tbody>
    </table>
    </template>
  </b-table>
  <!-- Pagination -->
  <b-pagination size="sm" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"/>
</b-card>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from 'vuex'
export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('tasks') }
    },
    data () {
      return {
        date_start: '',
        date_end: '',
        filters: {
          text: {
            order_num: '',
            created_at: '',
            customer: '',
            phone: '',
            total: 0,
            total_drop: 0
          },
          select: {
            order_status: ''
          }
        },
        apiUrl: process.env.VUE_APP_ENDPOINT_URL,
        items: [],
        fields: [
          {
            key: 'selected',
            label: '',
            sortable: false
          },
          {
            key: 'order_num',
            label: 'Номер замовлення',
            typesort: 'text'
          },
          {
            key: 'created_at',
            label: 'Дата',
            typesort: 'doubleinput'
          },
          {
            key: 'order_status',
            label: 'Статус зам.',
            typesort: 'select'
          },
          {
            key: 'ready',
            label: 'Готовність',
            class: 'text-center'
          }
        ],
        currentItems: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        totalItems: 0,
        infoModal: {
          id: 'info-modal',
          title: '',
          order: ''
        },
        selected: [],
        selectIds: [],
        selectIdStages: [],
        selectIdProduct: [],
        selectProductStages: [],
        search: '',
        search_sku: '',
        search_color: '',
        urlParams: {},
        workingData: [],
        selectedProcess: null,
        processOptions: []
      }
    },
    mounted () {
      if (this.currentShift) {
        this.fetchData().catch(error => {
            console.log(error)
        })
      }
        
      this.setStatuses()
      this.fetchWorkingProcess()
      if (this.deliveries === null || this.payments === null) {
        this.setDeliveries()
        this.setPayments()
      }
    },
    computed: {
      ...mapGetters({
        deliveries: 'order/deliveries',
        payments: 'order/payments',
        statuses: 'order/statuses',
        currentShift: 'shift/shift'
      }),
      // eslint-disable-next-line vue/return-in-computed-property
      orderStatuses () {
        if (this.statuses) {
          return this.statuses.filter(s => s.type === 'order')
        }
      },
      // eslint-disable-next-line vue/return-in-computed-property
      paymentStatuses () {
        if (this.statuses) {
          return this.statuses.filter(s => s.type === 'payment')
        }
      },
      // eslint-disable-next-line vue/return-in-computed-property
      hasOneRowOpen () {
        for (const item of this.items) {
          if (item._showDetails) {
            return true
          }
        }
      }
    },
    methods: {
      ...mapActions({
        setDeliveries: 'order/setDeliveries',
        setPayments: 'order/setPayments',
        setStatuses: 'order/setStatuses',
        setProcessId: 'shift/setProcessId'
      }),
      async fetchData () {
        const params = this.buildQuery() !== '' ? '&' + this.buildQuery() : ''
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const { data } = await axios.get(apiUrl + 'tasks?page=' + this.currentPage + params)
        this.items = data.data
        this.currentPage = data.meta.pagination.current_page
        this.perPage = data.meta.pagination.per_page
        this.totalItems = data.meta.pagination.total
      },
      searching () {
        this.fetchData()
      },
      async changeOrderStatus (orderId, e) {
        const id = e.target.value
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'orders/status', { order: orderId, status: id })
        this.$root.$refs.loading.finish()
      },
      async takeToWork () {
        if (this.currentShift) {
          if (this.workingData.length > 0) {
            const wd = this.workingData
            this.$root.$refs.loading.start()
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            await axios.post(apiUrl + 'tasks/take', {
              selectedProcess: this.selectedProcess,
              data: wd
            })
            // set process to cockies
            this.setProcessId(this.selectedProcess)
            this.$root.$refs.loading.finish()
            // this.fetchData()
            document.location.reload()
          } else {
            Swal.fire({
              title: 'Помилка!',
              text: 'Виберіть замовлення або етапи',
              icon: 'error',
              confirmButtonText: 'Ok'
            })
          }
        } else {
          Swal.fire({
            title: 'Помилка!',
            text: 'Відкрийте або поновіть зміну',
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
      },
      async fetchWorkingProcess () {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'working/process').then(resp => {
          if (resp.data) {
            this.processOptions = resp.data
            // this.processOptions.push({ value: null, text: 'Виберіть виробничий процес' })
          }
        })
      },
      async setStatus (status) {
        if (this.workingData.length > 0) {
          this.$root.$refs.loading.start()
          const apiUrl = process.env.VUE_APP_ENDPOINT_V1
          await axios.post(apiUrl + 'tasks/statuses', { status: status, data: this.workingData }).then(resp => {
            this.$root.$refs.loading.finish()
            this.fetchData()
          })
        } else {
          Swal.fire({
            title: 'Помилка!',
            text: 'Віберіть замовлення або етапи',
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
      },
      clearFilter () {
        this.urlParams = {}
        this.date_start = ''
        this.date_end = ''
        this.fetchData()
      },
      searchByText (key, val) {
        this.urlParams[key] = val
        this.fetchData()
      },
      searchBySelect (key, e) {
        const value = e.target.value
        this.urlParams[key] = value
        this.fetchData()
      },
      searchByDate (key, val) {
        this.urlParams[key] = val
        this.fetchData()
      },
      buildQuery () {
        return Object.entries(this.urlParams).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
      },
      // select all orders with stages
      selectAll (e) {
        this.selectIds = []
        if (e.target.checked) {
          for (const item in this.items) {
            this.selectIds.push(this.items[item].id)
            const order = this.items.find(o => o.id === this.items[item].id)
            if (order.products) {
              const products = []
              order.products.data.forEach(p => {
                if (p.stages) {
                  const stages = []
                  p.stages.forEach(s => {
                    const st = this.selectIdStages.find(si => si === s.id)
                    if (typeof st === 'undefined') {
                      this.selectIdStages.push(s.id)
                      stages.push(s.id)
                    }
                  })
                  products.push({
                    orderProductId: p.id,
                    stages: stages
                  })
                  this.selectIdProduct.push(p.id)
                }
              })
              this.selectProductStages = products
            }
            // set working data
            const wd = this.workingData.find(w => w.orderId === this.items[item].id)
            if (typeof wd === 'undefined') {
              this.workingData.push({
                orderId: this.items[item].id,
                chars: this.selectProductStages
              })
            } else {
              this.workingData = []
              this.workingData.push({
                orderId: this.items[item].id,
                chars: this.selectProductStages
              })
            }
          }
        } else {
          this.selectIdStages = []
          // set working data
          this.workingData = []
          this.selectProductStages = []
          this.selectIdProduct = []
        }
      },
      // select single stage checkbox
      selectStage (e, stageId, orderId, productId) {
        const order = this.workingData.find(w => w.orderId === orderId)
        if (e.target.checked) {
          const stages = []
          if (typeof order === 'undefined') {
            stages.push(stageId)
            const c = []
            c.push({ orderProductId: productId, stages: stages })
            this.workingData.push({ orderId: orderId, chars: c })
          } else {
            const p = order.chars.find(s => s.orderProductId === productId)
            if (typeof p !== 'undefined') {
              const stage = p.stages.find(s => s === stageId)
              if (typeof stage === 'undefined') {
                p.stages.push(stageId)
              }
            } else {
              stages.push(stageId)
              order.chars.push({ orderProductId: productId, stages: stages })
            }
          }
        } else {
          const pr = order.chars.find(p => p.orderProductId === productId)
          const i = pr.stages.findIndex(s => s === e.target.value)
          pr.stages.splice(i, 1)
          if (pr.stages.length === 0) {
            const pi = order.chars.findIndex(p => p.orderProductId === productId)
            order.chars.splice(pi, 1)
          }
          if (order.chars.length === 0) {
            const wi = this.workingData.findIndex(w => w.orderId === orderId)
            this.workingData.splice(wi, 1)
          }
        }
      },
      selectProduct (e, productId, orderId) {
        const order = this.workingData.find(w => w.orderId === orderId)
        if (e.target.checked) {
          const stages = []
          const chars = []
          chars.push({
            orderProductId: productId,
            stages: stages
          })
          if (typeof order === 'undefined') {
            this.workingData.push({
              orderId: orderId,
              chars: chars
            })
          } else {
            order.chars.push({
              orderProductId: productId,
              stages: stages
            })
          }
        } else {
          const i = order.chars.findIndex(w => w.orderProductId === e.target.value)
          order.chars.splice(i, 1)
          if (order.chars.length === 0) {
            const wi = this.workingData.findIndex(w => w.orderId === orderId)
            this.workingData.splice(wi, 1)
          }
        }
      },
      showAllDetails () {
        this.currentItems.forEach((item) => {
          this.$set(item, '_showDetails', true)
        })
      },
      hideAllDetails () {
        this.currentItems.forEach((item) => {
          this.$set(item, '_showDetails', false)
        })
      }
    },
    watch: {
        currentPage: {
            handler: function (value) {
                this.fetchData().catch(error => {
                    console.log(error)
                })
            }
        }
    }
}
</script>
<style>
.table {
  font-size: 0.9rem!important;
}
.table th, .table td {
  padding: 0.30rem!important;
}
</style>
